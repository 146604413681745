import React from 'react';
const MyReport = React.lazy(() => import('./views/myReport/myReport'));
// const ContactUs = React.lazy(() => import('./views/contactUs'));
const MyAnalyzer = React.lazy(() => import('./views/myAnalyzer'));
const ReportMenu = React.lazy(() => import('./views/myReport/reportMenu'));
const CreateReport = React.lazy(() => import('./views/myReport/createReport'));
const GenerateTemplate = React.lazy(() => import('./views/myReport/createReport/generateReport/generateTemplate'));
const CustomReport = React.lazy(() => import('./views/myReport/createReport/customReport/customReport'));
const EditCustomReport = React.lazy(() => import('./views/myReport/createReport/customReport/customReport'));
const CustomTemplate = React.lazy(() => import('./views/myReport/createReport/customReport/customTemplate'));
const Checkout = React.lazy(() => import('./views/myReport/createReport/checkout'));
const ThankYou = React.lazy(() => import('./views/myReport/createReport/thankYou'));
const QuickReport = React.lazy(() => import('./views/myReport/quickReport.js'));
// const GenerateReportTemplate = React.lazy(() => import('./views/myReport/createReport/generateReport/reportTemplate/reportTemplate'));
const GenerateReportTemp = React.lazy(() => import('./views/myReport/createReport/generateReport/reportTemplate/generateReportBar/reportTemp'));
const TraditionalReport = React.lazy(() => import('./views/myReport/TraditionalReport/TraditionalReportTemp'));

const routes = [
    { path: '/', exact: true, name: 'myReport' },
    { path: '/myReport', exact: true, name: 'myReport', component: MyReport },
    // { path: '/contactUs', exact: true, name: 'contactUs', component: ContactUs },
    { path: '/myAnalyzer', exact: true, name: 'myAnalyzer', component: MyAnalyzer },
    { path: '/reportMenu', exact: true, name: 'reportMenu', component: ReportMenu },
    { path: '/createReport', exact: true, name: 'createReport', component: CreateReport },
    { path: '/generateTemplate', exact: true, name: 'generateTemplate', component: GenerateTemplate },
    // { path: '/generateReportTemplate', exact: true, name: 'customTemplate', component: GenerateReportTemplate },
    // { path: '/generateReportTemplate/:id', exact: true, name: 'customTemplate', component: GenerateReportTemplate },
    { path: '/generateReportTemp', exact: true, name: 'customTemplate', component: GenerateReportTemp },
    { path: '/generateReportTemp/:id', exact: true, name: 'customTemplate', component: GenerateReportTemp },
    { path: '/customTemplate', exact: true, name: 'customTemplate', component: CustomTemplate },
    { path: '/customReport', exact: true, name: 'customReport', component: CustomReport },
    { path: '/customReport/:id', exact: true, name: 'customTemplate', component: EditCustomReport },
    { path: '/checkout/:id', exact: true, name: 'checkout', component: Checkout },
    { path: '/thankyou', exact: true, name: 'thankyou', component: ThankYou },
    { path: '/quickReport', exact: true, name: 'quickReport', component: QuickReport },
    { path: '/traditionalReport', exact: true, name: 'traditionalReport', component: TraditionalReport },
]

export default routes;