import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { createBrowserHistory } from "history";
import React, { useEffect } from 'react';
import routes from './routes';
import "react-datepicker/dist/react-datepicker.css";

const history = createBrowserHistory();

function App(props) {

  const { loader } = useSelector(state => state.loader)

  return (
    <React.Fragment>
    {
      loader && <div className="bc-loader">
        <svg xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="50" cy="50" fill="none" stroke="#1d0e0b" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
          </circle>
        </svg>
      </div>
    }
    <BrowserRouter history={history}>
      {/* {props.loader && <div className="animated fadeIn pt-3 text-center">Loading...</div>} */}
      <React.Suspense fallback="">
        <Switch>
          {routes.map((route, idx) => {
            return route.component ? (
              <Route
                key={idx}
                exact={route.exact}
                path={route.path}
                name={route.name}
                render={props => (
                  <route.component {...props} />
                )} />
            ) : (null);
          })}
          <Redirect from="/" to="/myReport" />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.loader.loader,
  };
};

export default connect(mapStateToProps)(App);
