const appState = {
    userData: null,
    reportData: null,
    sourceData: null,
    sourceDataCustom: null,
    reportMenuData: null,
    myAnaylzrData: null,
    fileUploadData: null,
    customDataById: null,
    playerData: null,
    comparisonData: null,
    templateList: null,
    generateTemp: null,
    checkoutTempList: [],
    creatTemplate: null,
    sendMailReport: null,
    editCustomData: null,
    contactUs: null,
    remainingCredits: null,
    conferenceList: null,
    teamList:null,
    scoutingReportList:null,
    customTemplate:null,
    scoutingTemplate:null,
    traditionalData:null,

    reportListData:null,
    levelListData:null,
    stadiumListData:null
};
export default function app(state = appState, action) {
    switch (action.type) {
        case "USER_DATA": {
            return { ...state, userData: action.payload };
        } 
        case "REPORT_DATA": {
            return { ...state, reportData: action.payload };
        }
        case "GET_SOURCE_DATA": {
            return { ...state, sourceData: action.payload };
        }
        case "GET_SOURCE_DATA_CUSTOM": {
            return { ...state, sourceDataCustom: action.payload };
        }
        case "REPORT_MENU_DATA": {
            return { ...state, reportMenuData: action.payload };
        }
        case "MY_ANAYLZR_DATA": {
            return { ...state, myAnaylzrData: action.payload };
        }
        case "FILE_UPLOAD_DATA": {
            return { ...state, fileUploadData: action.payload };
        }
        case "CUSTOM_REPORT_DATA_BY_ID": {
            return { ...state, customDataById: action.payload };
        }
        case "GET_PLAYER_DATA": {
            return { ...state, playerData: action.payload };
        }
        case "GET_SOURCE_COMPARISON_DATA": {
            return { ...state, comparisonData: action.payload };
        }
        case "GET_TEMPLATE_LIST": {
            return { ...state, templateList: action.payload };
        }
        case "GENERATE_TEMP": {
            return { ...state, generateTemp: action.payload };
        }
        case "CHECKOUT_TEMP_LIST": {
            return { ...state, checkoutTempList: action.payload };
        }
        case "CREATE_TEMP": {
            return { ...state, creatTemplate: action.payload };
        }
        case "SEND_MAIL_REPORT_DATA": {
            return { ...state, sendMailReport: action.payload };
        }
        case "UPDATE_CUSTOM_REPORT": {
            return { ...state, editCustomData: action.payload };
        }
        case "CONTACT_US": {
            return { ...state, contactUs: action.payload };
        }
        case "REMAINING_CREDITS": {
            return { ...state, remainingCredits: action.payload };
        }
        // traditional scouting
        case "GET_CONFERENCE_LIST": {
            return { ...state, conferenceList: action.payload };
        }
        case "GET_TEAM_LIST": {
            return { ...state, teamList: action.payload };
        }
        case "GET_SCOUTING_REPORT_LIST": {
            return { ...state, scoutingReportList: action.payload };
        }
        case "GET_CUSTOM_SCOUTING_LIST": {
            return { ...state, customTemplate: action.payload };
        }
        case "GENERATE_SCOUTING_REPORT": {
            return { ...state, scoutingTemplate: action.payload };
        }
        case "GET_TRADITIONAL_DATA": {
            return { ...state, traditionalData: action.payload };
        }

        //get report list

        case "GET_REPORT_DATA": {
            return { ...state, reportListData: action.payload };
        }

        case "GET_LEVEL_DATA": {
            return { ...state, levelListData: action.payload };
        }

        case "GET_STADIUM_DATA": {
            return { ...state,stadiumListData: action.payload };
        }

        default:
            return state;
    }
}
